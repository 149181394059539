import { useCallback } from 'react';
import { useEventEmitter } from './useEventEmitter';

export const useEmit = (eventName: string) => {
  const eventEmitter = useEventEmitter();

  const emit = useCallback(
    (...args: any[]) => {
      eventEmitter.emit(eventName, ...args);
    },
    [eventEmitter, eventName],
  );

  return emit;
};

import { useBoolean, useMemoizedFn } from 'ahooks';
import { Card, Segmented, Tabs } from 'antd';
import { useMemo, useState } from 'react';
import { QueryTable } from 'src/components/QueryTable';
import { NoticeTypeEnum, StatusEnum } from 'src/api/types/common';
import { getNoticeHistory, markRead } from 'src/api/clients';
import { ActionEnum } from 'src/components/QueryTable/types';
import { NoticeModal } from 'src/components/NoticeModal';
import { INoticeHistoryItem } from 'src/api/types/system';
import { useEmit } from 'src/hooks/useEmit';
import { NOTICE_MESSAGE } from 'src/constants/const';
import { columns } from './config';

const QueryTablePersonalMessage = 'QueryTablePersonalMessage';

interface IFormQuery {
  noticeType?: NoticeTypeEnum;
  status?: string;
  offset?: number;
  limit?: number;
}

export default function PersonalMessage() {
  const [activeKey, setActiveKey] = useState(NoticeTypeEnum.NOTICE);
  const [status, setStatus] = useState<string>('-1');
  const [noticeHistoryItem, setNoticeHistoryItem] = useState<INoticeHistoryItem | null>(null);
  const [open, { setTrue, setFalse }] = useBoolean(false);
  const emit = useEmit(NOTICE_MESSAGE);
  const tabKeys = useMemo(() => {
    return [
      { key: NoticeTypeEnum.NOTICE, label: '通知' },
      { key: NoticeTypeEnum.ANNOUNCEMENT, label: '公告' },
    ];
  }, []);

  const getMessageList = useMemoizedFn(async (params: IFormQuery) => {
    const { noticeType, status, offset, limit } = params;
    const res = await getNoticeHistory({
      noticeType: noticeType || activeKey,
      status: status === '-1' ? undefined : status,
      offset,
      limit,
    });

    return {
      data: res,
      total: res?.length,
    };
  });

  const onRefresh = useMemoizedFn(() => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: ActionEnum.REQUEST_START,
        payload: {},
      },
      QueryTablePersonalMessage,
    );
  });

  const onMarkRead = useMemoizedFn(async (id: number) => {
    await markRead([id]);
    onRefresh();
    emit(id);
  });

  return (
    <Card className="m-4 rounded-md" styles={{ body: { padding: '8px 24px' } }}>
      <Tabs
        items={tabKeys}
        size="large"
        activeKey={activeKey}
        onChange={(e) => {
          setActiveKey(e as NoticeTypeEnum);
          QueryTable.ProviderGlobalDispatch(
            {
              type: ActionEnum.REQUEST_START,
              payload: {
                noticeType: e,
              },
            },
            QueryTablePersonalMessage,
          );
        }}
      />
      <QueryTable.Provider name={QueryTablePersonalMessage} requestFn={getMessageList}>
        <QueryTable.Table
          columns={[
            ...columns,
            {
              title: '操作',
              fixed: 'right',
              width: 100,
              renderType: 'Operation',
              renderProps: (col: INoticeHistoryItem) => {
                return {
                  buttons: [
                    col.status === StatusEnum.NORMAL
                      ? {
                          label: '已读',
                          onClick: () => {
                            onMarkRead(col.id);
                          },
                        }
                      : null,
                    {
                      label: '详情',
                      onClick: () => {
                        setNoticeHistoryItem(col);
                        setTrue();
                      },
                    },
                  ].filter(Boolean),
                };
              },
            },
          ]}
          tabsNode={
            <Segmented
              value={status}
              options={[
                { label: '全部', value: '-1' },
                { label: '未读', value: StatusEnum.NORMAL },
                { label: '已读', value: StatusEnum.STOP },
              ]}
              onChange={(value) => {
                setStatus(value);
                QueryTable.ProviderGlobalDispatch(
                  {
                    type: ActionEnum.REQUEST_START,
                    payload: {
                      status: value,
                    },
                  },
                  QueryTablePersonalMessage,
                );
              }}
            />
          }
        />
      </QueryTable.Provider>
      <NoticeModal
        open={open}
        data={noticeHistoryItem}
        title="消息详情"
        onClose={() => {
          setFalse();
          onRefresh();
        }}
      />
    </Card>
  );
}

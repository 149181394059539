import { Tag } from 'antd';
import type { QueryTableColumnProps } from 'src/components/QueryTable';
import { NoticeTypeEnum, StatusEnum } from 'src/api/types/common';
import dayjs from 'dayjs';

export const columns: QueryTableColumnProps = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
  },
  {
    title: '公告名称',
    dataIndex: 'noticeTitle',
    key: 'noticeTitle',
    width: 200,
  },
  {
    title: '公告类型',
    dataIndex: 'noticeType',
    key: 'noticeType',
    width: 150,
    render: (noticeType: string) => {
      return noticeType === NoticeTypeEnum.NOTICE ? (
        <Tag color="processing">通知</Tag>
      ) : (
        <Tag color="gold">公告</Tag>
      );
    },
  },
  {
    title: '公告状态',
    dataIndex: 'noticeStatus',
    key: 'noticeStatus',
    width: 100,
    render: (noticeStatus: string) => {
      return (
        <Tag color={noticeStatus === StatusEnum.NORMAL ? 'green' : 'red'}>
          {noticeStatus === StatusEnum.NORMAL ? '正常' : '禁用'}
        </Tag>
      );
    },
  },
  {
    title: '已读状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: string) => {
      return (
        <Tag color={status === StatusEnum.NORMAL ? 'orange' : 'green'}>
          {status === StatusEnum.NORMAL ? '未读' : '已读'}
        </Tag>
      );
    },
  },
  {
    title: '查看时间',
    dataIndex: 'readTime',
    key: 'readTime',
    width: 200,
    render: (readTime: string) => {
      return readTime ? dayjs(readTime).format('YYYY-MM-DD HH:mm:ss') : null;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    render: (createTime: string) => {
      return dayjs(createTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];
